import { SelectedItem } from "../../../../interfaces/SelectedItem";
import onlyUnique from "../../../filters/filterOnlyUnique";
import { firelikeId } from "../../checkers/firelikeid";
import { ROW_TYPE_SUBTOTAL } from "../../modelConstants/modelConstants";
import { dollarToCents } from "../configureCurrencyToCents";

export const configureSelectedItems = (selectedItems: any) => {
    // selectedItems -> convert rates & create query hooks
    let selectedItemsQueryHook: string[] = [];
    let selectedCategoriesQueryHook: string[] = [];
    let bundleItemIds: string[] = [];
    let selectedCategories: any[] = [];

    const items = selectedItems.items.map((i: SelectedItem) => {

      console.log("Row Type", i.rowType);

      if (i.rowType !== "singleItem") {
        
        // Specialty Types | subtotal | damageWaiver | flatFee | percentFee | label | discount |
        const { rowTotal, ...rest } = i;

        return {
          rowTotal: dollarToCents(rowTotal),
          ...rest,
        };
      }

     
      // The rest of this is for singleItem types | ie rental and bundle items.

      const {
        rates,
        rowTotal,
        selectedRateMeta,
        bundleItemsQueryHook,
        ...rest
      } = i;

      i.bundleItems &&
        i.bundleItems.forEach((i: any) => {
          bundleItemIds.push(i.bundleItemId);
        });

      i.categoriesQueryHook &&
        i.categoriesQueryHook.forEach((h: any) => {
          selectedCategoriesQueryHook.push(h);
        });

      i.categories &&
        i.categories.forEach((c: any) => {
          selectedCategories.push({
            name: c.name,
            id: c.id,
          });
        });

      selectedItemsQueryHook.push(i.id);

      const convertedRates = rates.map((r) => {
        return {
          rateName: r.rateName,
          rateRange: r.rateRange,
          rate: dollarToCents(r.rate),
          id: r.id ? r.id : firelikeId(),
        };
      });

      if (!selectedRateMeta)
        return {
          rates: convertedRates,
          rowTotal: dollarToCents(i.rowTotal),
          selectedRateMeta: null,
          ...rest,
        };

      const { rate, ...metaRest } = selectedRateMeta && selectedRateMeta;

      return {
        rates: convertedRates,
        rowTotal: dollarToCents(i.rowTotal),
        selectedRateMeta: {
          rate: dollarToCents(rate),
          ...metaRest,
        },
        ...rest,
      };

    });

    const resetItemKeys = items.map((i: SelectedItem, index: number) => {
      return {
        ...i,
        key: index,
        listIndex: index,
      };
    });

    return {
      selectedItems: {
        items: resetItemKeys,
        count: selectedItems.count,
      },
      categoriesQueryHook: selectedCategoriesQueryHook.filter(onlyUnique),
      selectedItemsQueryHook: selectedItemsQueryHook,
      bundleItemsQueryHook: bundleItemIds.filter(onlyUnique),
      selectedCategories: selectedCategories,
    };
  };
