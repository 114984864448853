import { SubscriptionPlanDetails } from "./production";

export const functionsBaseUrl = "https://us-central1-adelie-logistics-dev-52205.cloudfunctions.net/";

export const firebaseConfig = {
  apiKey: "AIzaSyARqZIrU21liaJ-OZhVcXhlmyR10SjJ-6s",
  authDomain: "adelie-logistics-dev-52205.firebaseapp.com",
  databaseURL: "https://adelie-logistics-dev-52205.firebaseio.com",
  projectId: "adelie-logistics-dev-52205",
  storageBucket: "adelie-logistics-dev-52205.appspot.com",
  messagingSenderId: "455931163528",
  appId: "1:455931163528:web:ebda532104c21d88fc403d",
  measurementId: "G-4G89J3PQ8Q",
};

export const algolia_invoices = {
  appid: "GRBZR8I3VR",
  searchKey: "c35308145ed0f8835cd1c3829bfa2165",
  index: "invoices",
};

export const algolia_inventory = {
  appid: "GRBZR8I3VR",
  searchKey: "c35308145ed0f8835cd1c3829bfa2165",
  index: "inventory",
};

export const justifiConfig = {
  clientId: "test_e7ed9c8ee714e17d4eb0b6b74f325f7d"
}

export const stripeSubscriptionConfig = {
  publishableKey: "pk_test_xDhPzoUIeRcZICW5xCjYcc9K",
  pricingTableId: "prctbl_1OvN7c2MgodgWSYZpKfru09Y",
  portalUrl: "https://billing.stripe.com/p/login/test_28o15kdi64webII4gg"
};

export const subscriptionPlanDetails: SubscriptionPlanDetails[] = [
  {
    stripe_id: "basics_monthly",
    subName: "basics",
    name: "Adelie Basics",
    price: 3500,
    description: "Adelie Basic Plan",
    features: [
      "Unlimited Users",
      "Inventory Tracking",
      "Limited Mobile App Features",
      "Sales and Tax Reports",
      "Unlimited Products",
      "Payment Processing",
      "Embedded Checkout",
    ],
  },
  {
    stripe_id: "basics_annual",
    subName: "basics",
    name: "Adelie Basics",
    price: 34800,
    description: "Adelie Basic Plan",
    features: [
      "Unlimited Users",
      "Inventory Tracking",
      "Limited Mobile App Features",
      "Sales and Tax Reports",
      "Unlimited Products",
      "Payment Processing",
      "Embedded Checkout",
    ],
  },

  {
    stripe_id: "essentials_monthly",
    name: "Adelie Essentials",
    subName: "essentials",
    price: 7900,
    description: "Adelie Essentials Plan",
    features: [
      "Everything In Basics",
      "Inventory Item Preparation Tools",
      "Vendors, Venues, and Tags",
      "Invoice Customization",
    ],
  },
  {
    stripe_id: "essentials_annual",
    name: "Adelie Essentials",
    subName: "essentials",
    price: 70800,
    description: "Adelie Essentials Plan",
    features: [
      "Everything In Basics",
      "Inventory Item Preparation Tools",
      "Vendors, Venues, and Tags",
      "Invoice Customization",
    ],
  },

  {
    stripe_id: "plus_monthly",
    name: "Adelie Plus",
    subName: "plus",
    price: 14900,
    description: "Adelie Plus Plan",
    features: [
      "Everything In Essentials",
      "Routing",
      "Full Mobile App Features",
      "Bundle Items",
      "Email Templates",
      "File Manager and Attachments",
      "Internal Notes and Chat",
      "QuickBooks Integrations",
    ],
  },
  {
    stripe_id: "plus_annual",
    name: "Adelie Plus",
    subName: "plus",
    price: 138000,
    description: "Adelie Plus Plan",
    features: [
      "Everything In Essentials",
      "Routing",
      "Full Mobile App Features",
      "Bundle Items",
      "Email Templates",
      "File Manager and Attachments",
      "Internal Notes and Chat",
      "QuickBooks Integrations",
    ],
  },

  {
    stripe_id: "premium_monthly",
    name: "Adelie Premium",
    subName: "premium",
    price: 24900,
    description: "Adelie Premium Plan",
    features: [
      "Everything In Plus",
      "Email Automation",
      "Invoice Saved Filters",
      "Priority Support",
      "Priority Feature Request",
      "Website Integration Assistance",
    ],
  },
  {
    stripe_id: "premium_annual",
    name: "Adelie Premium",
    subName: "premium",
    price: 238800,
    description: "Adelie Premium Plan",
    features: [
      "Everything In Plus",
      "Email Automation",
      "Invoice Saved Filters",
      "Priority Support",
      "Priority Feature Request",
      "Website Integration Assistance",
    ],
  },
];
