import Checkbox from "@mui/material/Checkbox";
import {
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import Iconify from "../../../app/iconify";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import {
  INV_ITEM_RATE,
  INV_ITEM_RATE_NAME,
  INV_ITEM_RATE_RANGE,
} from "../../../app/utils/models/modelConstants/modelConstants";

import { makeStyles } from "@mui/styles";
import { firelikeId } from "../../../app/utils/models/checkers/firelikeid";
import { enqueueSnackbar } from "notistack";
import { fCurrency } from "../../reports/sales/components/format-number";
import { green, red, yellow } from "@mui/material/colors";

// ----------------------------------------------------------------------

type Props = {
  item: any;
  index: number;
  isSalesInvoice: boolean;
  availability: any;

  // selected: boolean;
  // onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
  // onEditRow: VoidFunction;
  handleUpdateSelectedItem: (index: number, item: any) => void;
  onChangeItem: VoidFunction;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function BundleRow({
  item,
  index,
  isSalesInvoice,
  availability,
  handleUpdateSelectedItem,
  onChangeItem,
  onDeleteRow,
}: Props) {
  // Text Field Refs
  const descriptionRef = useRef<HTMLInputElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const durationRef = useRef<HTMLInputElement>(null);

  const responsiveFontSize = useResponsiveFontSize();
  const { currentBreakpoint, isSMAndDown, isMDAndDown } = useCurrentBreakpoint();
  const classes = useStyles();

  const theme = useTheme();

  const [itemName, setItemName] = useState(item?.name || "");
  const [itemDescription, setItemDescription] = useState(
    item?.selectedDescription || ""
  );
  const [duration, setDuration] = useState(item?.selectedDuration || 0);
  const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
  const [quantity, setQuantity] = useState(item?.selectedQty || 1);

  const [total, setTotal] = useState(item?.rowTotal || 0);
  const [showBundleItems, setShowBundleItems] = useState(false);

  const [rate, setRate] = useState(
    item?.selectedRateMeta || {
      rate: 0,
      rateName: "",
      rateRange: "",
    }
  );

  const [customAmt, setCustomAmt] = useState<any>(null);
  const [customRange, setCustomRange] = useState<any>("day");

  const configureDurationOpts = (type: string) => {
    if (type === "sale") {
      // return <Option value={"none"}>None</Option>;
    } else {
      const opts = [
        { label: "Per hour", value: "hour" },
        { label: "Per day", value: "day" },
        { label: "Per week", value: "week" },
        { label: "Per month", value: "month" },
      ];
      return opts;
    }
  };
  const handleCustomRate = () => {
    // create custom rate obj with unique name
    const rate = {
      id: firelikeId(),
      [INV_ITEM_RATE]: customAmt,
      [INV_ITEM_RATE_RANGE]: customRange,
      [INV_ITEM_RATE_NAME]: `Custom ${item.rates ? item.rates.length + 1 : 1}`,
    };

    item.rates.push(rate);
    setRate(rate);
    setCustomAmt(0);
    setCustomRange("day");
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    updateItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  // Calculate the total based on quantity, rate, and duration
  const calculateTotal = useCallback(() => {
    if (item.type === "sale") {
      setTotal(quantity * rate.rate);
    } else {
      setTotal(rate.rate * duration * quantity);
    }
  }, [quantity, duration, rate.rate, item.type]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  const updateItem = () => {
    item.selectedDescription = itemDescription;
    item.selectedQty = quantity;
    item.selectedDuration = duration;
    item.selectedTaxable = taxable;
    item.rowTotal = total;
    item.selectedRateMeta = rate;

    handleUpdateSelectedItem(item.listIndex, item);
  };

  const handleDurationChange = (event: any) => {
    const value = event.target.value;
    setDuration(value === "" ? "" : Number(value));
  };


  const NumberCircle = ({ available }: {available: number}) => {

    console.log("available", available);

    if (available === undefined || available === null || isNaN(available)) {
      return (
        <Tooltip title={"No availability data"}>
        <Typography 
        fontWeight={"bold"}
        fontSize={responsiveFontSize - 4}
       
      >
        {`...` }
      </Typography>
      </Tooltip>
    )
    }

    let backgroundColor;
    if (available < 0) {
      backgroundColor = red[500];
    } else if (available === 0) {
      backgroundColor = yellow[700];
    } else {
      backgroundColor = green[500];
    }

    let finalNumber = available.toString();
    if (available > 999) {
      finalNumber = "+1k";
    }
  
    return (
      <Tooltip title={`Available: ${available}`}>
      <Typography 
        fontWeight={"bold"}
        fontSize={10}
        sx={{
          color: backgroundColor,
        }}
      >
        {`${finalNumber}` }
      </Typography>
      </Tooltip>  
    )
  };

  const BundleItemNumberCircle = ({ available }: {available: number}) => {

    console.log("available", available);

    if (available === undefined || available === null || isNaN(available)) {
      return (
        <Tooltip title={"No availability data"}>
        <Typography 
        fontWeight={"bold"}
        fontSize={responsiveFontSize - 4}
       
      >
        {`...` }
      </Typography>
      </Tooltip>
    )
    }

    let backgroundColor;
    if (available < 0) {
      backgroundColor = red[500];
    } else if (available === 0) {
      backgroundColor = yellow[700];
    } else {
      backgroundColor = green[500];
    }

    let finalNumber = available.toString();
    if (available > 999) {
      finalNumber = "+1k";
    }
  
    return (
      <Tooltip title={`Available: ${available}`}>
      <Typography 
        fontWeight={"bold"}
        fontSize={12}
        sx={{
          color: backgroundColor,
        }}
      >
        {`Available: ${finalNumber}` }
      </Typography>
      </Tooltip>  
    )
  };

  const getAvailabilityForId = (id: string) => {
    const foundAvailablilty = availability[id];
    return parseInt(availability[id]) ?? 0;
  }

  return (
    <Box>
      <Grid
        sx={{ backgroundColor: theme.palette.background.default }}
        container
        spacing={{ xs: 2, lg: 1, xl: 2 }}
        alignItems="center"
        columns={24}
      >
        {/* //MARK: - Item */}
        <Grid item xs={24} sm={12} lg={6}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Badge badgeContent={index + 1} color="primary" sx={{mr: 2}}>
              <DragIndicatorIcon />
            </Badge>
            <Chip
              label="Bundle"
              onClick={() => {
                setShowBundleItems(!showBundleItems);
              }}
            />
            <TextField
              onClick={onChangeItem}
              inputProps={{
                sx: {
                  fontSize: responsiveFontSize,
                  readOnly: true,
                },
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
              size="small"
              label="Item Name"
              variant="outlined"
              fullWidth
              value={itemName}
            />
          </Stack>
        </Grid>

        {/* //MARK: - Description */}
        <Grid item xs={24} sm={12} lg={7} xl={8}>
          <TextField
            inputProps={{ style: { fontSize: responsiveFontSize } }}
            inputRef={descriptionRef}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            fullWidth
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - Quantity */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            inputRef={quantityRef}
            className={classes.input}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Quantity"
            type="number"
            variant="outlined"
            fullWidth
            InputProps={{
              inputProps: {
                min: 1,
                style: { fontSize: responsiveFontSize },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <NumberCircle available={getAvailabilityForId(item.id)} />
                </InputAdornment>
              ),
            }}
            placeholder="0"
            value={quantity === 0 ? "" : quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (quantityRef.current) {
                  quantityRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - Rate */}
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextField
            disabled={true}
            inputProps={{
              sx: {
                fontSize: responsiveFontSize,
                readOnly: true,
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={handleClick}>
                  <Iconify icon="carbon:edit" />
                </IconButton>
              ),
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Rate"
            variant="outlined"
            fullWidth
            value={`$${rate.rate} / ${rate.rateRange}`}
          />
        </Grid>

        {/* //MARK: - Duration */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            required={true}
            disabled={isSalesInvoice}
            inputRef={durationRef}
            className={classes.input}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Duration"
            type="number"
            variant="outlined"
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
                style: { fontSize: responsiveFontSize },
              },
            }}
            placeholder="0"
            value={duration}
            onChange={handleDurationChange}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (durationRef.current) {
                  durationRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - TAXABLE */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={taxable}
                onChange={(e) => {
                  setTaxable(e.target.checked);
                }}
              />
            }
            label={
              <Typography fontSize={responsiveFontSize}>
                {isMDAndDown ? "Taxable" : "Taxed"}
              </Typography>
            }
          />
        </Grid>

        {/* //MARK: - TOTAL */}
        <Grid item xs={12} sm={12} md={4} lg={1}>
          <div
            style={{ width: "100%", textAlign: "left", paddingRight: "16px" }}
          >
            {useMediaQuery(theme.breakpoints.up("lg")) && (
              <Typography fontSize={responsiveFontSize}>{fCurrency(total)}</Typography>
            )}
            {useMediaQuery(theme.breakpoints.down("lg")) && (
              <Typography fontSize={responsiveFontSize}>
                Total: {fCurrency(total)}
              </Typography>
            )}
          </div>
        </Grid>

        {/* //MARK: - ACTIONS */}
        <Grid item alignContent={"end"} xs={12} sm={12} md={4} lg={1}>
          <Box
            display="flex"
            justifyContent={isMDAndDown ? "flex-end" : "flex-start"}
            width="100%"
          >
            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              onClick={() => onDeleteRow(item.listIndex)}
            />
          </Box>
        </Grid>
      </Grid>

      {showBundleItems && (
        <Stack sx={{ p: 1, ml: 4 }}>
          <Typography gutterBottom fontSize={responsiveFontSize}>
            Bundle Items:
          </Typography>
          <Stack gap={1}>
            {item.bundleItems.map((bundleItem: any, index: number) => {
              return (
                <Stack direction={'row'} alignItems={'center'} gap={3}>
                <Typography fontSize={responsiveFontSize - 1}>
                  <Chip size="small" label={`${bundleItem.bundleItemQty}x`} />{" "}
                  {bundleItem.bundleItemName}
                </Typography>

                  {/* TODO: Check this is right. Using the lowest common currently looks like */}
                  <BundleItemNumberCircle available={getAvailabilityForId(bundleItem.bundleItemId)} /> 
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}

      {/* //MARK: - RATE POPOVER */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: { width: "300px" },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1 }}>
          <List>
            {item &&
              item.rates &&
              item.rates.map((r: any) => (
                <ListItemButton
                  key={r.id}
                  onClick={() => {
                    setRate(r);
                    handleClose();
                  }}
                >
                  <Typography variant="body2">
                    {`${r.rateName} (${r.rate}${
                      item.type === "sale" ? "" : ` per ${r.rateRange}`
                    })`}
                  </Typography>
                </ListItemButton>
              ))}
          </List>

          {/* <Divider style={{ margin: "4px 0" }} /> */}
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            CUSTOM RATE
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Stack direction={"row"} gap={1}>
              <TextField
                className={classes.input}
                size="small"
                label="Amount"
                type="number"
                placeholder="0.00"
                value={customAmt === 0 ? "" : customAmt}
                onChange={(e) => setCustomAmt(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{ typography: "subtitle2", color: "text.disabled" }}
                      >
                        $
                      </Box>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                style={{ width: "125px" }}
              />
              <FormControl style={{ width: "140px" }}>
                <InputLabel>Duration</InputLabel>
                <Select
                  onChange={(event) => {
                    setCustomRange(event.target.value);
                  }}
                  value={customRange}
                  size="small"
                >
                  {configureDurationOpts(item.type)?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <IconButton
                onClick={() => {
                  if (customAmt) {
                    handleCustomRate();
                  } else {
                    enqueueSnackbar("Please enter a rate amount", {
                      variant: "error",
                    });
                  }
                }}
              >
                <Iconify
                  icon="material-symbols:add-circle"
                  color={theme.palette.success.dark}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
