import { Invoice } from '../../../app/interfaces/invoice';
import { deepEqual } from 'fast-equals';

export interface Diff {
  fieldName: string;
  oldValue: any;
  newValue: any;
}

const ignoredKeys = [
  'qbo', 'syncToken', 'qrItems', 'id', 'orgId', 'selectedItemsQueryHook', 
  'historicalId', 'updateSource', 'lastSyncDate',
  'rentalDateStart', 'rentalDateEnd', 'rentalTurnaroundEnd', 'mobileStartDate', 'mobileEndDate', 'selectedCategories', 'updatedAt',
  'deliveryLat', 'deliveryLng', 'hasReceiveWindow', 'emailDeliveryFailed', 'onSiteLng', 'onSiteLat', 
  'hasSpecifiedReturnTime', 'hasSpecifiedReceiveTime',
];

// Generic function to remove any property
const removeProperty = <T, K extends keyof T>(prop: K) => 
  (obj: T): Omit<T, K> => {
    const { [prop]: _, ...rest } = obj;
    return rest;
  };

function shouldIgnoreKey(key: string): boolean {
  return ignoredKeys.some(ignoredKey => key.startsWith(ignoredKey));
}

export function compareInvoices(newInvoice: Invoice, oldInvoice: Invoice): Diff[] {
    const diffs: Diff[] = [];

    if (deepEqual(newInvoice, oldInvoice)) {
      console.log('equal invoices!!');
    }
  
    for (const key in newInvoice) {
      if (newInvoice.hasOwnProperty(key)) {
        // Skip keys that we don't care about
        if (shouldIgnoreKey(key)) continue;
  
        // Handle selectedItems as a special case
        if (key === 'selectedItems') {

          const removeUuid = removeProperty<any, 'uuid'>('uuid');

          const newSelectedItems = newInvoice.selectedItems.items;
          const oldSelectedItems = oldInvoice.selectedItems.items;

          console.log(newSelectedItems, oldSelectedItems);
  
          if (!deepEqual(newInvoice.selectedItems.items.map(removeUuid), oldInvoice.selectedItems.items.map(removeUuid))) {
            diffs.push({
              fieldName: key,
              oldValue: oldSelectedItems,
              newValue: newSelectedItems,
            });
          }
  
          continue;
        }
  
        const newValue = newInvoice[key as keyof Invoice];
        const oldValue = oldInvoice[key as keyof Invoice];
  
        if (!deepEqual(newValue, oldValue)) {
          diffs.push({
            fieldName: key,
            oldValue,
            newValue,
          });
        }
      }
    }
  
    return diffs.sort((a, b) => a.fieldName.localeCompare(b.fieldName));;
  }
  
