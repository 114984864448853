import { firelikeId } from "../../checkers/firelikeid";
import {
  INV_BUNDLE_ITEM,
  RENTAL_ITEM,
  ROW_TYPE_BUNDLE_ITEM,
  ROW_TYPE_SALE_ITEM,
  ROW_TYPE_SINGLE_ITEM,
} from "../../modelConstants/modelConstants";
import moment from "moment";

export const configureNewSingleItem = (selectedItems, customer) => {
  const tax = !customer?.isTaxExempt ?? false;
  return {
    key: selectedItems.count,
    rowType: ROW_TYPE_SINGLE_ITEM,
    rowTotal: 0,
    name: `New Invoice Item`,
    editable: true,
    selectedQty: 0,
    selectedRate: "Select rate",
    selectedRateMeta: null,
    selectedTaxable: tax,
    selectedDuration: 1,
    selectedDescription: "",
    uuid: firelikeId(),
    hasValidatedFields: false,
    availableStock: null,
  };
};

export const configureNewSingleItemMUI = (selectedItems, customer, item) => {
  const tax = !customer?.isTaxExempt ?? false;

  // check if there are rates, if there are seelct first one that is "day" and set that to selectedRateMeta
  let selectedRateMeta = null;
  if (item.rates && item.rates.length > 0) {
    const dayRate = item.rates.find((rate) => rate.rateRange === "day");
    if (dayRate) {
      selectedRateMeta = dayRate;
    }
  }

  return {
    key: selectedItems.length,
    listIndex: selectedItems.length,
    rowType: ROW_TYPE_SINGLE_ITEM,
    type: RENTAL_ITEM,
    rowTotal: 0,
    name: item.name,
    selectedQty: 1,
    selectedRate: selectedRateMeta ? selectedRateMeta.rateName : "Select rate",
    selectedRateMeta: selectedRateMeta ? selectedRateMeta : null,
    selectedTaxable: tax,
    selectedDuration: 1,
    selectedDescription: item.description,
    uuid: firelikeId(),
    availableStock: null,
    ...item,
  };
};

export const configureNewInvoiceFromCart = (cart, rentalDates) => {
  const selectedItems = cart.map((c, i) => {
    return {
      ...c,
      key: i,
      id: c.id,
      selectedItemId: c.id,
      rowType: ROW_TYPE_SINGLE_ITEM,
      rowTotal: 0,
      name: c.name,
      editable: true,
      selectedQty: c.count,
      rates: c.rates ? c.rates : [],
      selectedRate: c.rates && c.rates[0] ? c.rates[0].rateName : "Select rate",
      selectedRateMeta: c.rates && c.rates[0] ? c.rates[0] : null,
      selectedTaxable: c.isTaxable,
      selectedDuration: 0,
      selectedDescription: c.description,
      uuid: firelikeId(),
      hasValidatedFields: false,
      availableStock: null,
    };
  });
  return {
    selectedItems: {
      items: selectedItems,
      count: cart.length,
    },
    rentalDates: rentalDates,
  };
};
